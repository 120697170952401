<template>
  <div class="component-menu-slider">
    <Carousel class="text-center" :value="items" :numVisible="8" :numScroll="1" :responsiveOptions="responsiveSettings">
      <template #item="item">
        <button @click="this.selectItem()" class="btn-clean color-40 alt">{{ item.data }}</button>
      </template>
    </Carousel>
  </div>
</template>
<script>

import Carousel from 'primevue/carousel'

export default {
  name: 'MenuSlide',
  components: {
    Carousel
  },
  data () {
    return {
      responsiveSettings: [
        {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 1
        },
        {
          breakpoint: '640px',
          numVisible: 1,
          numScroll: 1
        }
      ]
    }
  },
  props: {
    items: {
      type: Array,
      default () {
        return ['item1', 'item2']
      }
    }
  },
  methods: {
    selectItem (event) {
      console.log('Click')
    }
  }
}

</script>
