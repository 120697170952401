<template>

  <div class="menu-page">
    <div class="pt-1 pb-1 container-padding flex align-center bg-secondary color-00">
      <div class="mr-a flex align-center">
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 0H1V2H17V0ZM18 10V8L17 3H1L0 8V10H1V16H11V10H15V16H17V10H18ZM9 14H3V10H9V14Z" fill="white"/>
        </svg>
        <p class="color-00 alt ml-1 mr-1">El Califa Condesa</p>
        <button class="btn-white-secondary btn-alt">Ver Información</button>
      </div>
      <button class="btn-clean"><u>Cambiar Ubicación</u>
        <svg class="ml-1" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                fill="white"/>
        </svg>
      </button>
    </div>

    <cover>
      <template #background>
        <img src="https://via.placeholder.com/1920x440?text=adomicilio cover" alt="Adomicilio">
      </template>
      <h1>Menú</h1>
    </cover>

    <div class="flex align-center justify-space-between container-padding pt-1 pb-1 md-direction-column">
      <div class="search md-full sm-mb-1">
        <label class="icon-left mr-2">
          <input type="text" placeholder="Busca Alimentos, Categorias, Descripciones">
          <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.1667 0.333008H1.83341C1.05425 0.333008 0.423831 0.970508 0.423831 1.74967L0.416748 10.2497C0.416748 11.0288 1.05425 11.6663 1.83341 11.6663H13.1667C13.9459 11.6663 14.5834 11.0288 14.5834 10.2497V1.74967C14.5834 0.970508 13.9459 0.333008 13.1667 0.333008ZM12.8834 3.34342L7.8755 6.47426C7.64883 6.61592 7.35133 6.61592 7.12467 6.47426L2.11675 3.34342C1.93966 3.23009 1.83341 3.03884 1.83341 2.83342C1.83341 2.35884 2.3505 2.07551 2.75425 2.32342L7.50008 5.29134L12.2459 2.32342C12.6497 2.07551 13.1667 2.35884 13.1667 2.83342C13.1667 3.03884 13.0605 3.23009 12.8834 3.34342Z"
              fill="#747474"/>
          </svg>
        </label>
      </div>
      <div class="information ml-a mr-a flex align-center sm-direction-column md-full md-mb-1">

        <div class="info flex direction-column mr-2 sm-full sm-mr-0 sm-mb-1">
          <p class="min color-secondary">Métodos de Pago Disponible</p>
          <div class="bottom flex align-center">
            <p class="alt mt-0 mr-1">
          <span>
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.833008 4.50005L2.49967 6.16672C6.64134 2.02505 13.358 2.02505 17.4997 6.16672L19.1663 4.50005C14.108 -0.558285 5.89967 -0.558285 0.833008 4.50005ZM7.49967 11.1667L9.99967 13.6667L12.4997 11.1667C11.1247 9.78338 8.88301 9.78338 7.49967 11.1667ZM4.16634 7.83338L5.83301 9.50005C8.13301 7.20005 11.8663 7.20005 14.1663 9.50005L15.833 7.83338C12.6163 4.61672 7.39134 4.61672 4.16634 7.83338Z"
              fill="#12529F"/>
          </svg>
          En linea
          </span>
            </p>
            <p class="alt mt-0">
          <span>
          <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.833 8.66658V1.99992C15.833 1.08325 15.083 0.333252 14.1663 0.333252H2.49967C1.58301 0.333252 0.833008 1.08325 0.833008 1.99992V8.66658C0.833008 9.58325 1.58301 10.3333 2.49967 10.3333H14.1663C15.083 10.3333 15.833 9.58325 15.833 8.66658ZM14.1663 8.66658H2.49967V1.99992H14.1663V8.66658ZM8.33301 2.83325C6.94967 2.83325 5.83301 3.94992 5.83301 5.33325C5.83301 6.71658 6.94967 7.83325 8.33301 7.83325C9.71634 7.83325 10.833 6.71658 10.833 5.33325C10.833 3.94992 9.71634 2.83325 8.33301 2.83325ZM19.1663 2.83325V11.9999C19.1663 12.9166 18.4163 13.6666 17.4997 13.6666H3.33301C3.33301 12.8333 3.33301 12.9166 3.33301 11.9999H17.4997V2.83325C18.4163 2.83325 18.333 2.83325 19.1663 2.83325Z"
              fill="#12529F"/>
          </svg>
          En Sucursal
          </span>
            </p>
          </div>
        </div>

        <div class="info flex direction-column mr-2 sm-full sm-mr-0 sm-mb-1">
          <p class="min color-secondary">Monto Mínimo de Orden</p>
          <div class="bottom flex align-center">
            <p class="alt mt-0">$150.00</p>
          </div>
        </div>

        <div class="info flex direction-column mr-2 sm-full sm-mr-0 sm-mb-1">
          <p class="min color-secondary">Métodos de Pago Disponible</p>
          <div class="bottom flex align-center">
            <p class="min mt-0 mr-1">
          <span>
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.833008 4.50005L2.49967 6.16672C6.64134 2.02505 13.358 2.02505 17.4997 6.16672L19.1663 4.50005C14.108 -0.558285 5.89967 -0.558285 0.833008 4.50005ZM7.49967 11.1667L9.99967 13.6667L12.4997 11.1667C11.1247 9.78338 8.88301 9.78338 7.49967 11.1667ZM4.16634 7.83338L5.83301 9.50005C8.13301 7.20005 11.8663 7.20005 14.1663 9.50005L15.833 7.83338C12.6163 4.61672 7.39134 4.61672 4.16634 7.83338Z"
              fill="#12529F"/>
          </svg>
          En linea
          </span>
            </p>
            <p class="alt mt-0">
          <span>
          <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.833 8.66658V1.99992C15.833 1.08325 15.083 0.333252 14.1663 0.333252H2.49967C1.58301 0.333252 0.833008 1.08325 0.833008 1.99992V8.66658C0.833008 9.58325 1.58301 10.3333 2.49967 10.3333H14.1663C15.083 10.3333 15.833 9.58325 15.833 8.66658ZM14.1663 8.66658H2.49967V1.99992H14.1663V8.66658ZM8.33301 2.83325C6.94967 2.83325 5.83301 3.94992 5.83301 5.33325C5.83301 6.71658 6.94967 7.83325 8.33301 7.83325C9.71634 7.83325 10.833 6.71658 10.833 5.33325C10.833 3.94992 9.71634 2.83325 8.33301 2.83325ZM19.1663 2.83325V11.9999C19.1663 12.9166 18.4163 13.6666 17.4997 13.6666H3.33301C3.33301 12.8333 3.33301 12.9166 3.33301 11.9999H17.4997V2.83325C18.4163 2.83325 18.333 2.83325 19.1663 2.83325Z"
              fill="#12529F"/>
          </svg>
          En Sucursal
          </span>
            </p>
          </div>
        </div>

      </div>
      <div class="md-full sm-mr-0 sm-mb-1">
        <button class="btn-clean color-secondary"><u>Ordenar el Open Table</u>
          <svg class="ml-1" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                  fill="#12529F"/>
          </svg>
        </button>
      </div>
    </div>

    <div :class="categories.length <= 10 ? 'container-box' : 'container-padding'">
      <menu-slide :class="`mt-1 mb-1 ${categories.length > 10 ? 'md' : ''}`" v-bind:items="categories"></menu-slide>
      <div class="flex">
        <template v-if="categories.length > 10">
          <div class="flex direction-column menu-column pr-2 mt-2 no-md">
            <button v-for="(category, index) in categories" v-bind:key="index" :class="{ 'active-left': index === 0 }"
                    class="btn-clean alt color-40 active justify-start mb-2">{{ category }}
            </button>
          </div>
        </template>
        <div>
          <div class="top flex align-center">
            <h1 class="color-secondary">Categoria</h1>
            <p class="alt ml-a">9 Resultados</p>
          </div>
          <div class="grid-columns-3 mb-4">
            <a-card v-for="n in 9" v-bind:orientation="'horizontal'" v-bind:key="n" v-bind:item="this.dish"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="modalAddressVisible">
    <h1 class="color-secondary-on">Selecciona tu Sucursal</h1>
    <label class="mb-2">
      <input type="text" placeholder="Sucursal">
    </label>
    <p class="search-item alt mb-1">
      <span class="label">Condesa</span><br>
      Alfonso Reyes 273, Hipódromo Condesa, 06100, Miguel Hidalgo
    </p>
    <hr class="bg-color-20 mb-1">
    <div class="mt-4 controls flex direction-column align-center">
      <button>Cambiar Surursal</button>
      <button class="mt-1 btn-clean color-40"><u>Cancelar</u></button>
    </div>
  </Dialog>

</template>
<script>
import Cover from '@/components/Cover'
import MenuSlide from '@/components/MenuSlide'
import ACard from '@/components/Card'
import Dialog from 'primevue/dialog'

export default {
  components: {
    Cover,
    MenuSlide,
    ACard,
    Dialog
  },
  data () {
    return {
      modalAddressVisible: true,
      categories: ['category-1', 'category-2', 'category-3', 'category-4', 'category-5', 'category-6', 'category-7', 'category-8', 'category-9', 'category-10', 'category-11'],
      dish: {
        name: 'Nombre del Platillo',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab consequuntur magni.',
        price: 50,
        favButton: true,
        onCard: 0
      }
    }
  }
}
</script>
<style lang="scss">
.menu-page {
  .search {
    @media (min-width: $md + 1) {
      width: 600px;
      max-width: 30%;
    }
  }

  .menu-column {
    width: 300px;
    max-width: 100%;
  }

  .container-padding { // MENU LATERAL
    .grid-columns-3 {
      @media (max-width: $xl) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: $sm) {
        grid-template-columns: 1fr;
      }
    }
  }
}
</style>
